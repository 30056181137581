export const environment = {
    unified: true,
    production: false,
    country: `${process.env['DEFAULT_COUNTRY']}`,
    apiUrl: `${process.env['API_URL']}`,
    tokenUrl: `${process.env['TOKEN_API_URL']}`,
    firebase: {
        projectId: '',
        apiKey: `${process.env['APIKEY']}`,
        authDomain: `${process.env['AUTHDOMAIN']}`,
    },
    appUrl: {
        bssUrl: `${process.env['BSS_URL']}`,
        adminUrl: `${process.env['ADMIN_URL']}`,
        authUrl: `${process.env['AUTH_URL']}`
    }
};
