import { Injectable } from '@angular/core';
import { persistState, Store, StoreConfig } from '@datorama/akita';
import { UserAuth } from '../../model/user.auth';

export type SessionState = UserAuth;

export function createInitialState(): SessionState {
    return {
        displayName: '',
        uid: '',
        email: '',
        photoURL: '',
        emailVerified: false,
        accessToken: '',
        providerId: null,
        phoneNumber: null,
        userExtractedToken: null,
    };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'session' })
export class SessionStore extends Store<SessionState> {
    constructor() {
        super(createInitialState());
    }

    updateToken(accessToken: any) {
        this.update((state: any) => ({
            ...state,
            accessToken,
        }));
    }
}

export const sessionPersistStorage = persistState({
    include: ['session'],
    key: 'sessionStore',
});

const providers = [
    { provide: 'persistStorage', useValue: sessionPersistStorage },
];
