import { Component, OnInit } from '@angular/core';
import { ThemeService } from './core/services/theme.service';
import { environment } from 'src/environments/environment';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
    constructor(private themeService: ThemeService) {}

    ngOnInit(): void {
        this.themeService.switchTheme(environment.country.toLowerCase());
    }

}
