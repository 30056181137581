import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
    AngularFirestore,
    AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { UserAuth } from '../model/user.auth';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { createInitialState, SessionStore } from '../state/session/session.store';
import { FirebaseError } from 'firebase/app';
import jwt_decode from 'jwt-decode';
import { Router } from '@angular/router';

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class AuthService {
    userData: any;

    constructor(
        public afs: AngularFirestore,
        public afAuth: AngularFireAuth,
        public router: Router,
        private sessionStore: SessionStore
    ) {
        this.afAuth.authState.pipe(untilDestroyed(this)).subscribe(async (user) => {
            if (user) {
                this.userData = user;
                const { accessToken } = this.userData.multiFactor.user;
                this.sessionStore.update({
                    ...this.userData.multiFactor.user,
                    ...{ accessToken: accessToken },
                    ...{ userExtractedToken: jwt_decode(accessToken) },
                });
                this.afAuth.updateCurrentUser(user);
            } else {
                this.sessionStore.update(createInitialState());
            }
        });
    }

    /**
     *
     * @param email input user email for firebase login
     * @param password  input user password for firebase login
     * @returns user and error values
     *
     */
    async signIn(email: string, password: string) {
        let errorMessage = '';
        try {
            const result: any = await this.afAuth.signInWithEmailAndPassword(
                email,
                password
            ).then((userCredential: any) => {
                userCredential.user.getIdToken().then((result: any) => {
                    localStorage.setItem('firebaseToken', result);
                });
                return userCredential;
            });

            this.setUserAuthData(result.user);
            this.sessionStore.setLoading(false);
            return { user: result.user, error: null };
        } catch (error: any) {
            if (error instanceof FirebaseError) {
                console.error(error.code);
            }
            switch (error.code) {
                case 'auth/invalid-email':
                case 'auth/wrong-password':
                case 'auth/user-not-found': {
                    errorMessage = 'Wrong email address or password.';
                    break;
                }
                case 'auth/too-many-requests': {
                    errorMessage = 'Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.';
                    break;
                }
                default: {
                    errorMessage = 'Unexpected Error';
                    break;
                }
            }
            this.sessionStore.setLoading(false);
            return {
                user: null,
                error: errorMessage,
            };
        }
    }

    /**
     *
     * @param user | SET USER REF SESSION IN ANGULAR FIRE
     * @returns user reference for angular firedocument
     */
    setUserAuthData(user: any) {
        const userRef: AngularFirestoreDocument<any> = this.afs.doc(
            `users/${user.uid}`
        );
        const userData: UserAuth = {
            uid: user.uid,
            email: user.email,
            displayName: user.displayName,
            photoURL: user.photoURL,
            emailVerified: user.emailVerified,
            phoneNumber: user.phoneNumber,
            providerId: user.providerId,
        };
        return (
            userRef.set(userData),
            {
                merge: true,
            }
        );
    }

    async signOut(urlParams?: any) {
        return this.afAuth.signOut().then(() => {
            this.sessionStore.update(createInitialState());
            this.sessionStore.setLoading(false);
            this.router.navigate(['auth/login']).then(() => {
                if (urlParams) {
                    setTimeout(() => {
                        window.location.href += `?redirectUrl=${urlParams}`;
                    })
                }
                else {
                    location.reload();
                }
            });
        });
    }

    isTokenExpired(exp: number | null) {
        if (exp) {
            return new Date().getTime() > new Date(exp * 1000).getTime();
        }
        return true;
    }

    async renewToken() {
        return this.afAuth.currentUser.then((user) => {
            if (user) {
                return user.getIdToken(true);
            }
            return Promise.resolve(null);
        });
    }
}
