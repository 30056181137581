import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard.component';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { SharedPrimeNgModule } from 'src/app/shared/shared-prime-ng.module';
import { ReactiveFormsModule } from '@angular/forms';

const routes: Routes = [
    {
        path: '',
        component: DashboardComponent,
    },
];

@NgModule({
    declarations: [
        DashboardComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        SharedPrimeNgModule,
        ReactiveFormsModule,
        RouterModule.forChild(routes),
        SharedModule,
    ],
    providers: [],
})
export class DashboardModule {}
