import { inject } from '@angular/core';
import {
    CanActivateFn,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router,
    CanActivateChildFn,
} from '@angular/router';
import { map, catchError, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserAuth } from '../model/user.auth';
import { SessionQuery } from '../state/session/session.query';

export const AuthGuardFn: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
) => {
    const authService = inject(SessionQuery);
    const router = inject(Router);

    return authService.selectUser$.pipe(
        map((data: UserAuth) => {
            if (!(data && data.accessToken)) return true;
            const { redirectUrl } = route.queryParams;
            if (redirectUrl) {
                setTimeout(() => {
                    window.location.href = `${redirectUrl}?token=${data.accessToken}`;
                });
            } else {
                window.location.href = `${environment.appUrl.bssUrl}?token=${data.accessToken}`;
            }
            router.navigate(['verify']);
            return false;
        }),
        catchError(() => {
            router.navigate(['auth/login']);
            return of(false);
        })
    );
};

export const AuthGuardChild: CanActivateChildFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
) => AuthGuardFn(route, state);
