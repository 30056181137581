import { Component, OnInit } from '@angular/core';
import { ActivatedRoute} from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthService } from 'src/app/core/services/auth.service';

@UntilDestroy()
@Component({
  selector: 'app-logout',
  template: '<ng-container></ng-container>'
})
export class LogoutComponent implements OnInit {

  constructor(private authService: AuthService,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.activatedRoute.queryParams.pipe(untilDestroyed(this)).subscribe(async (data) => {
      if('logout' in data && 'redirectUrl' in data ) {
          const { logout } = data;
          const { redirectUrl } = data;
          if(logout || redirectUrl) {
            await this.authService.signOut(redirectUrl);
          }
      }else if('logout' in data && !('redirectUrl' in data )) {
        const { logout } = data;
        if(logout) {
          await this.authService.signOut();
        }
      }
    });
  }

}
