import { inject } from '@angular/core';
import {
    CanActivateFn,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router,
    CanActivateChildFn,
} from '@angular/router';
import { map, catchError, of } from 'rxjs';
import { UserAuth } from '../model/user.auth';
import { SessionQuery } from '../state/session/session.query';

export const BatchGuardFn: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
) => {
    const authService = inject(SessionQuery);
    const router = inject(Router);
    return authService.selectUser$.pipe(
        map((data: UserAuth) => {
            if (data.accessToken) return true;
            router.navigate(['auth/login']);
            return false;
        }),
        catchError(() => {
            router.navigate(['auth/login']);
            return of(false);
        })
    );
};

export const BatchGuardChild: CanActivateChildFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
) => BatchGuardFn(route, state);
