import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { CardModule } from 'primeng/card';
import { InputTextModule } from 'primeng/inputtext';
import { CheckboxModule } from 'primeng/checkbox';
import { PanelMenuModule } from 'primeng/panelmenu';
import { SidebarModule } from 'primeng/sidebar';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TreeModule } from 'primeng/tree';
import { ToastModule } from 'primeng/toast';
import { ContextMenuModule } from 'primeng/contextmenu';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { MenuModule } from 'primeng/menu';
import { TabMenuModule } from 'primeng/tabmenu';
import { SkeletonModule } from 'primeng/skeleton';
import { DialogModule } from 'primeng/dialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MultiSelectModule } from 'primeng/multiselect';
import { TagModule } from 'primeng/tag';
import { ChipModule } from 'primeng/chip';
import { CalendarModule } from 'primeng/calendar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FileUploadModule } from 'primeng/fileupload';
import { PanelModule } from 'primeng/panel';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DragDropModule } from 'primeng/dragdrop';
import { MessageService } from 'primeng/api';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { InputSwitchModule } from 'primeng/inputswitch';
import { DividerModule } from 'primeng/divider';
import { MenubarModule } from 'primeng/menubar';

@NgModule({
    imports: [
        CommonModule,
        ButtonModule,
        CardModule,
        InputTextModule,
        CheckboxModule,
        PanelMenuModule,
        SidebarModule,
        DropdownModule,
        InputTextareaModule,
        TreeModule,
        ToastModule,
        ContextMenuModule,
        SplitButtonModule,
        TableModule,
        TooltipModule,
        MenuModule,
        RippleModule,
        TabMenuModule,
        SkeletonModule,
        DialogModule,
        ConfirmDialogModule,
        MultiSelectModule,
        TagModule,
        ChipModule,
        CalendarModule,
        RadioButtonModule,
        FileUploadModule,
        PanelModule,
        ConfirmPopupModule,
        DragDropModule,
        MessagesModule,
        MessageModule,
        InputSwitchModule,
        DividerModule,
        MenubarModule,
    ],
    exports: [
        CommonModule,
        ButtonModule,
        CardModule,
        InputTextModule,
        CheckboxModule,
        PanelMenuModule,
        SidebarModule,
        DropdownModule,
        InputTextareaModule,
        TreeModule,
        ToastModule,
        ContextMenuModule,
        SplitButtonModule,
        TableModule,
        TooltipModule,
        MenuModule,
        RippleModule,
        TabMenuModule,
        SkeletonModule,
        DialogModule,
        ConfirmDialogModule,
        MultiSelectModule,
        TagModule,
        ChipModule,
        CalendarModule,
        RadioButtonModule,
        FileUploadModule,
        PanelModule,
        ConfirmPopupModule,
        DragDropModule,
        MessagesModule,
        MessageModule,
        InputSwitchModule,
        DividerModule,
        MenubarModule,
    ],
    providers: [MessageService],
})
export class SharedPrimeNgModule {}
