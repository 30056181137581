import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
    providedIn: 'root',
})
export class ThemeService {
    constructor(@Inject(DOCUMENT) private document: Document) {}

    /**
     * @param theme pass country theme via country code name ( e.g AU or NZ )
     */
    switchTheme(theme: string) {
        const themeLink = this.document.getElementById(
            'app-theme'
        ) as HTMLLinkElement;
        if (themeLink) {
            themeLink.href = theme + '.css';
        }
    }
}
