import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedPrimeNgModule } from './shared-prime-ng.module';
import { FormsModule } from '@angular/forms';
import { DateOrEmptyPipe } from './pipes/dateOrEmpty.pipe';
import { TrimTextPipe } from './pipes/trim.text.pipe';

@NgModule({
    declarations: [

        DateOrEmptyPipe,
        TrimTextPipe,
    ],
    imports: [CommonModule, FormsModule, SharedPrimeNgModule],
    exports: [

        DateOrEmptyPipe,
        TrimTextPipe,
    ],
    providers: [TrimTextPipe],
})
export class SharedModule {}
