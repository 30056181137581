import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { environment } from 'src/environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthService } from './core/services/auth.service';
import { AuthModule } from './pages/auth/auth.module';
import { SharedPrimeNgModule } from './shared/shared-prime-ng.module';
import { SharedModule } from './shared/shared.module';

import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptorService } from './core/interceptors/auth.interceptor.service';
import { LogoutComponent } from './pages/logout/logout.component';
import { DashboardModule } from './pages/dashboard/dashboard.module';

@NgModule({
    declarations: [AppComponent, LogoutComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireAuthModule,
        AngularFirestoreModule,
        AngularFireModule,
        AppRoutingModule,
        SharedModule,
        SharedPrimeNgModule,
        LoadingBarModule,
        LoadingBarRouterModule,
        HttpClientModule,
        AuthModule,
        DashboardModule,
        environment.production
            ? []
            : AkitaNgDevtools.forRoot({
                  maxAge: 25,
              }),
    ],
    providers: [
        AuthService,
        AuthInterceptorService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptorService,
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
