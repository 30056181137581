import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { Routes, RouterModule } from '@angular/router';
import { AuthComponent } from './auth.component';
import { SharedPrimeNgModule } from 'src/app/shared/shared-prime-ng.module';
import { ReactiveFormsModule } from '@angular/forms';
import { LogoComponent } from 'src/app/shared/components/logo/logo.component';

const routes: Routes = [
    {
        path: '',
        component: AuthComponent,
        children: [
            {
                path: 'login',
                component: LoginComponent,
            },
        ],
    },
];

@NgModule({
    declarations: [LoginComponent, AuthComponent],
    exports: [RouterModule],
    imports: [
        CommonModule,
        RouterModule,
        SharedPrimeNgModule,
        ReactiveFormsModule,
        RouterModule.forChild(routes),
        LogoComponent,
    ],
})
export class AuthModule {}
