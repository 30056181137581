import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
} from '@angular/common/http';
import { SessionQuery } from '../state/session/session.query';
import { from, switchMap } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { SessionStore } from '../state/session/session.store';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
    constructor(
        private sessionQuery: SessionQuery,
        private sessionStore: SessionStore,
        private authService: AuthService
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const token = this.sessionQuery.getValue().accessToken;
        const exp = this.sessionQuery.getValue().userExtractedToken?.exp;
        const tokenExpire = this.authService.isTokenExpired(exp ? exp : null);
        const contentType = req.headers.get('Content-Type');
        const bearer = req.headers.get('Authorization');
        if (token && !tokenExpire) {
            const request = req.clone({
                setHeaders: {
                    'Content-Type': contentType
                        ? contentType
                        : 'application/json; charset=utf-8',
                    Accept: contentType ? contentType : 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });

            return next.handle(request);
        } else if(bearer && !token) {
            const request = req.clone({
                setHeaders: {
                    'Content-Type': 'application/json; charset=utf-8',
                    Accept: contentType ? contentType : 'application/json',
                    Authorization: `${bearer}`,
                },
            });
            return next.handle(request);

        } else if (token && tokenExpire) {
            return from(this.authService.renewToken()).pipe(
                switchMap((newToken) => {
                    this.sessionStore.updateToken(newToken);
                    const request = req.clone({
                        headers: req.headers.set(
                            'Authorization',
                            'Bearer ' + newToken
                        ),
                    });
                    return next.handle(request);
                })
            );
        }
        return next.handle(req);
    }
}
