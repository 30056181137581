import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { MessageService } from 'primeng/api';
import { AuthService } from 'src/app/core/services/auth.service';
import { SessionQuery } from 'src/app/core/state/session/session.query';
import { environment } from 'src/environments/environment';
import { SessionStore } from 'src/app/core/state/session/session.store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
    declare loginForm: FormGroup;
    loader = this.loadingBar.useRef();
    submitted = false;
    isLoginLoading$ = this.sessionQuery.selectLoading();
    country: any | string = environment.country;

    constructor(
        public authService: AuthService,
        public router: Router,
        private sessionStore: SessionStore,
        private sessionQuery: SessionQuery,
        private messageService: MessageService,
        private loadingBar: LoadingBarService,
        private route: ActivatedRoute
    ) { }

    ngOnInit() {
        this.sessionStore.setLoading(false);
        this.loginForm = new FormGroup({
            login: new FormControl<string | null>('', [
                Validators.required,
                Validators.email,
            ]),
            password: new FormControl<string | null>('', Validators.required),
            rememberMe: new FormControl<boolean | null>(false),
        });

        this.loginForm.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
            let password = this.loginForm.get('password');
            let login = this.loginForm.get('login');
            if (password && password.errors && password.hasError('incorrect')) {
                delete password.errors['incorrect'];
                password.updateValueAndValidity();
            }
            if (login && login.errors && login.hasError('incorrect')) {
                delete login.errors['incorrect'];
                login.updateValueAndValidity();
            }
        });
        
    }

    async onSubmit() {
        if (this.loginForm.valid) {
            this.sessionStore.setLoading(true);
            this.loader.start();
            const result = await this.authService.signIn(
                this.loginForm.value.login,
                this.loginForm.value.password
            );
            if (result && result.user) {
                setTimeout(() => {
                    const { user } = result;
                    const { multiFactor } = user;
                    const { accessToken } = multiFactor.user;
                    const { queryParams } = this.route.snapshot;
                    if(queryParams && 'redirectUrl' in queryParams) {
                        window.location.href=`${queryParams['redirectUrl']}?token=${accessToken}`;
                    } else {
                        window.location.href=`${environment.appUrl.bssUrl}?token=${accessToken}`;
                    }
                }, 500);
            } else {
                this.loginForm.controls['login'].setErrors({ incorrect: true });
                this.loginForm.controls['password'].setErrors({
                    incorrect: true,
                });
                this.showError(result.error);
            }
            this.loader.complete();
        }
    }

    showError(errorMsg: any) {
        this.messageService.add({
            key: 'br',
            severity: 'error',
            summary: 'Error',
            detail: errorMsg,
            closable: true,
        });
    }
}
